import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Button from "@mui/material/Button"
import logo from "../../assets/img/dataport.svg"
import {Link} from "react-router-dom"
import {getKeycloak} from "../../keycloak/keycloak"
import {SxProps} from "@mui/material"

const buttonStyles: SxProps = {
  textTransform: "none",
  color: "red",
  backgroundColor: "white",
  fontWeight: "bolder",
  fontSize: "27px",
  borderRadius: 4,
  fontFamily: "Audiowide",
  padding: "0 16px",
  marginRight: "8px",
  "&:hover": {
    backgroundColor: "red",
    color: "white",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
  },
  "&:active": {
    backgroundColor: "darkorange",
    boxShadow: "inset 0 0 5px rgba(0,0,0,0.3)"
  }
}

export const Header = () => {
  const [isLoading, setIsLoading] = React.useState(false)

  const logout = async () => {
    setIsLoading(true)
    ;(await getKeycloak()).logout()
    setIsLoading(false)
  }
  return (
    <Box sx={{width: "100%", height: "100%"}}>
      <AppBar position="static" sx={{backgroundColor: "lightgray", borderRadius: 2, width: 1240}}>
        <Toolbar sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Link to="/" style={{textDecoration: "none"}}>
            <img
              src={logo}
              className="dataport-logo"
              alt="Dataport"
              style={{height: "55px", width: "auto", marginRight: 2}}
            />
          </Link>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Button color="inherit" component={Link} to="/valido" sx={buttonStyles}>
              VALIDO
            </Button>
            <Button color="inherit" component={Link} to="/statistiken" sx={buttonStyles}>
              STATISTIK
            </Button>
            <Button color="inherit" component={Link} to="/information" sx={buttonStyles}>
              INFO
            </Button>
            <Button color="inherit" component={Link} to="/" sx={buttonStyles} onClick={logout} disabled={isLoading}>
              OUT
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
