import React from "react"
import {Box, Paper, Typography} from "@mui/material"
import {StatistikenResponse} from "../../models/StatistikenResponse"
import {getRequest} from "../../services/HttpService"
import {DataGrid, GridColDef} from "@mui/x-data-grid"

const paginationModel = {page: 0, pageSize: 10}

export function StatistikTable() {
  const [data, setData] = React.useState<StatistikenResponse[]>([])
  const [status, setStatus] = React.useState<"ERROR" | "FORBIDDEN" | "OK" | "EMPTY">("EMPTY")

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest<StatistikenResponse[]>("v1/archivvalidator/uebersicht", {
          handleResponseError: response => {
            if (response.status === 403) {
              setStatus("FORBIDDEN")
            } else {
              setStatus("ERROR")
            }
          }
        })
        if (response?.length === 0) {
          setStatus("EMPTY")
        }
        if (response) {
          setData(response)
          setStatus("OK")
        }
      } catch (error) {
        setStatus("ERROR")
        console.error("Request failed", error)
      }
    }

    fetchData()
  }, [])

  if (status === "EMPTY") {
    return (
      <div className="wrapper-message">
        <p className={"error-text"}>Für diesen Account liegen keine Validierungsergebnisse vor.</p>
      </div>
    )
  }

  if (status === "FORBIDDEN") {
    return (
      <div className="wrapper-message">
        <p className={"error-text"}>
          Dieser Account hat keine Berechtigungen, die Statistiken von Bundesländern anzuzeigen. Für einen Account mit
          Berechtigungen wenden Sie sich bitte an
          <br />
          <a href="mailto:dataportanbindungssupportpvog@dataport.de">dataportanbindungssupportpvog@dataport.de</a>
        </p>
      </div>
    )
  }

  if (status === "ERROR") {
    return (
      <div className="wrapper-message">
        <p className={"error-text"}>Fehler beim Abfragen der Daten!</p>
      </div>
    )
  }

  const formatSeccondsToDuration = (value: number) => {
    return new Date(value * 1000).toLocaleTimeString("de-DE", {
      timeZone: "UTC",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    })
  }
  const dateFormatter = (value: string) => {
    return new Date(value).toLocaleDateString("de-DE", {
      timeZone: "UTC",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      year: "2-digit",
      month: "2-digit",
      day: "2-digit"
    })
  }

  const columns: GridColDef[] = [
    {field: "landName", headerName: "Bundesland", width: 200},
    {field: "gesamt", headerName: "Gesamt"},
    {field: "fehlerhaft", headerName: "Fehler"},
    {field: "gesamt", headerName: "Warnungen"},
    {field: "hinweise", headerName: "Hinweise"},
    {field: "startZeit", headerName: "Start", valueGetter: value => dateFormatter(value), width: 200},
    {field: "dauer", headerName: "Dauer", valueGetter: value => formatSeccondsToDuration(value), width: 200}
  ]

  return (
    <Box sx={{padding: 4}}>
      {/* Small Headline */}
      <Typography variant="h4" sx={{textAlign: "center"}}>
        Statistiken
      </Typography>
      <br />

      {/* Description Text in Italic */}
      <Typography variant="body1" sx={{fontStyle: "italic", marginBottom: 4}}>
        Hier werden die Ergebnisse der regelmäßigen Validierungen angezeigt, die automatisch durchgeführt werden. Die
        manuell angestoßenen Validierungen werden momentan nicht gespeichert und werden hier nicht angezeigt.{" "}
      </Typography>
      <Paper sx={{height: 400, width: "100%"}}>
        <DataGrid
          rows={data}
          columns={columns}
          initialState={{pagination: {paginationModel}}}
          pageSizeOptions={[5, 10]}
          sx={{border: 0}}
        />
      </Paper>
    </Box>
  )
}
