import {Box, Typography, Button} from "@mui/material"
import React, {useEffect, useState} from "react"
import {KomplettReportResponse} from "../../models/ReportResponse"
import {getRequest} from "../../services/HttpService"
import {getKeycloak} from "../../keycloak/keycloak"
import {redaktionsSystemsArray} from "../../defaultParameter/redaktionsSystemsArray"
import {SxProps} from "@mui/material"

const buttonStyles: SxProps = {
  textTransform: "none",
  color: "white",
  backgroundColor: "red",
  fontWeight: "bolder",
  fontSize: "27px",
  borderRadius: 4,
  fontFamily: "Audiowide",
  padding: "0 16px",
  marginRight: "8px",
  width: "500px",
  "&:hover": {
    backgroundColor: "white",
    color: "red",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
  },
  "&:active": {
    backgroundColor: "darkblue",
    boxShadow: "inset 0 0 5px rgba(0,0,0,0.3)"
  }
}

export const DownloadReports: React.FC = () => {
  const [loading, setLoading] = useState<boolean[]>([])
  const [ids, setIds] = useState<string[]>([])
  const [error, setError] = useState<boolean[]>([])

  const fetchIds = async () => {
    try {
      const schemeAgencysIds: string[] = (await getKeycloak()).tokenParsed?.schemeAgencyIds || []
      setIds(schemeAgencysIds)
      setError(new Array(schemeAgencysIds.length).fill(false))
      setLoading(new Array(schemeAgencysIds.length).fill(false))
    } catch (error) {
      console.error("Error fetching IDs:", error)
    }
  }

  useEffect(() => {
    fetchIds()
  }, [])

  useEffect(() => {
    // Set a timeout for each button that is disabled to re-enable it after 5 seconds
    const timers: NodeJS.Timeout[] = []

    error.forEach((isDisabled, index) => {
      if (isDisabled) {
        const timer = setTimeout(() => {
          const newDisabledButtons = [...error]
          newDisabledButtons[index] = false // Re-enable the button
          setError(newDisabledButtons)
        }, 5000)
        timers.push(timer) // Store the timer to clean it up later
      }
    })

    // Clean up the timers when the component is unmounted or on re-render
    return () => {
      timers.forEach(timer => clearTimeout(timer))
    }
  }, [error])

  const handleDownload = async (id: string, index: number) => {
    const updateLoading = [...loading]
    updateLoading[index] = true
    setLoading(updateLoading)
    try {
      const response = await getRequest<KomplettReportResponse>(`v2/archivvalidator/komplettreport/neuester/${id}`, {
        handleResponseError: response => {
          const updatedError = [...error]
          updatedError[index] = true
          setError(updatedError)
          throw new Error(`Failed to fetch data for ID: ${id} with response ${response}`)
        }
      })
      if (!response) {
        const updatedError = [...error]
        updatedError[index] = true
        setError(updatedError)
        const updateLoading = [...loading]
        updateLoading[index] = false
        setLoading(updateLoading)
        return null
      }

      // Create a Blob from the JSON data
      const file = new Blob([JSON.stringify(response)], {type: "application/json"})

      // Create a hidden anchor element to trigger the download
      const element = document.createElement("a")
      element.href = URL.createObjectURL(file)
      element.download = `${schemeAgencyIdToName(id)}.json`

      // Programmatically trigger the download
      document.body.appendChild(element)
      element.click()

      // Clean up by removing the element
      document.body.removeChild(element)
    } catch (error) {
      console.error("Error during download:", error)
    } finally {
      const updateLoading = [...loading]
      updateLoading[index] = false
      setLoading(updateLoading)
    }
  }

  const schemeAgencyIdToName = (id: string) => {
    const foundRedSys = redaktionsSystemsArray.find(redSys => {
      return redSys.schemeAgencyId === id
    })
    if (foundRedSys) {
      return foundRedSys.name
    }
    return "Kein Bundesland gefunden zu: " + id
  }

  if (ids.length === 0) {
    return null
  }
  const loadingErrorTExt = (index: number, id: string) => {
    if (error[index]) return "Fehler beim Download."
    if (loading[index]) return "Wird heruntergeladen..."
    else return schemeAgencyIdToName(id)
  }

  return (
    <>
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: "20px",
            backgroundColor: "#f5f5f5"
          }}>
          <Typography variant="h3" component="h6" sx={{fontWeight: "bold", mb: 2}}>
            Validierungsstatistiken
          </Typography>
          <Typography variant="h5" component="h3" sx={{color: "gray", mb: 4}}>
            Download des letzten Validierungsreports:
          </Typography>
          <ul className="button-list">
            {ids.map((id, index) => (
              <li key={id} className="button-list-item">
                <Button
                  sx={buttonStyles}
                  onClick={() => handleDownload(id, index)}
                  disabled={loading[index] || error[index]}>
                  {loadingErrorTExt(index, id)}
                </Button>
              </li>
            ))}
          </ul>
        </Box>
      </div>
    </>
  )
}
