import * as React from "react"
import {DownloadReports} from "./DownloadReports"
import {StatistikTable} from "./StatistikTable"

export const Statistiken: React.FC = () => {
  return (
    <>
      <main>
        <div className="rectangle-gesamt">
          <DownloadReports />
          <StatistikTable />
        </div>
      </main>
    </>
  )
}
