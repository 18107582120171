import React, {useCallback, useEffect, useState} from "react"
import {Fortschritt} from "../components/ausgabe/Fortschritt"
import {FortschrittWrapper} from "../components/ausgabe/FortschrittWrapper"
import {Report} from "../components/ausgabe/Report"
import {ReportButtons} from "../components/ausgabe/ReportButtons"
import {Eingabe} from "../components/eingabe/Eingabe"
import {Redsyskonfig} from "../models/Redsyskonfig"
import {ValidierungsAnfrage} from "../models/ValidierungsAnfrage"
import {ValidierungsAnfrageResponse} from "../models/ValidierungsAnfrageResponse"
import {useValidationProgress} from "./hooks/useValidationProgress"
import {deleteRequest, getRequest, postRequest} from "../services/HttpService"

// Hauptkomponente, die alle anderen Komponenten einbindet
export const Validierung = () => {
  const {report, reportWirdGeneriert, progress, updateProgressStatus, startPolling, endPolling} = useValidationProgress(
    () => setzeValidierungBeendet(true)
  )
  const [isLoadingRedsyskonfig, setIsLoadingRedsyskonfig] = React.useState(true)
  const [redsyskonfig, setRedsyskonfig] = useState<Redsyskonfig>()
  const [id, setId] = useState<string>("")
  const [isCancelDisabled, setIsCancelDisabled] = useState<boolean>(true)
  const [isValidierungBeendet, setIsValidierungBeendet] = useState<boolean>(false)
  const [isValidateDisabled, setIsValidateDisabled] = useState<boolean>(true)

  const setzeButtonsFuerValidierung = (isValidating: boolean) => {
    setIsCancelDisabled(!isValidating)
    setIsValidateDisabled(isValidating)
  }

  const setzeValidierungBeendet = useCallback((isValDone: boolean) => {
    setIsValidierungBeendet(isValDone)
    setzeButtonsFuerValidierung(false)
  }, [])

  const ladeRedaktionssystem = useCallback(async (redsyscode: string) => {
    const konfig = await getRequest<Redsyskonfig>(`v1/${redsyscode}/redsyskonfig`)
    if (konfig) {
      setRedsyskonfig(konfig)
    }
    setIsLoadingRedsyskonfig(false)
  }, [])

  // Verarbeitung der Rückmeldung zu einer Valididerungsanfrage
  const verarbeiteRueckmeldung = useCallback(
    (result: ValidierungsAnfrageResponse, data?: ValidierungsAnfrage) => {
      updateProgressStatus(result.status)
      setId(result.id)
      startPolling(result.id)
      if (data) {
        history.replaceState(null, "", "?id=" + result.id + "&url=" + btoa(data.indexPfad) + "&rs=" + data.redsyscode)
      }
    },
    [startPolling, updateProgressStatus]
  )

  const fortschrittsAnfrage = useCallback(
    (validierungsId: string) => {
      setzeValidierungBeendet(false)
      updateProgressStatus("CONNECTING_TO_BACKEND")

      getRequest<ValidierungsAnfrageResponse>(`v1/${validierungsId}/fortschritt`, {
        handleResponseError: response => {
          if (response.status === 404) {
            updateProgressStatus("ERROR_VALIDATION_ID_NOT_FOUND")
          } else {
            updateProgressStatus("ERROR_INTERNAL_BACKEND")
          }
          setzeButtonsFuerValidierung(false)
        },
        handleCaughtError: () => {
          updateProgressStatus("ERROR_NO_BACKEND_RESPONSE")
          setzeButtonsFuerValidierung(false)
        }
      }).then(response => {
        if (response) {
          if (
            response.status === "WAITING" ||
            response.status === "READING_INDEX" ||
            response.status === "VALIDATING" ||
            response.status === "CONNECTING_TO_BACKEND" ||
            response.status === "TRYING_TO_RECONNECT"
          ) {
            setzeButtonsFuerValidierung(true)
          } else {
            setzeButtonsFuerValidierung(false)
          }

          response.status = "CONNECTING_TO_BACKEND"
          verarbeiteRueckmeldung(response)
        }
      })
    },
    [verarbeiteRueckmeldung, updateProgressStatus, setzeValidierungBeendet]
  )

  //Validierungsanfrage ans Backend per POST (Parameter sind Dateipfad, erlaubte Praefixe und Credentials)
  const validierungsAnfrage = (pfad: string, user: string, password: string, isAuthDisabled: boolean) => {
    setzeValidierungBeendet(false)
    updateProgressStatus("CONNECTING_TO_BACKEND")

    const data: ValidierungsAnfrage = {
      indexPfad: pfad,
      userName: !isAuthDisabled ? user : undefined,
      password: !isAuthDisabled ? password : undefined,
      redsyscode: redsyskonfig?.redsyscode
    }

    postRequest<ValidierungsAnfrageResponse>("v5/validiere", data, {
      handleResponseError: response => {
        if (response.status === 400) {
          updateProgressStatus("ERROR_BAD_REQUEST")
        } else {
          updateProgressStatus("ERROR_INTERNAL_BACKEND")
        }
        setzeButtonsFuerValidierung(false)
      },
      handleCaughtError: () => {
        updateProgressStatus("ERROR_NO_BACKEND_RESPONSE")
        setzeButtonsFuerValidierung(false)
      }
    }).then(result => {
      if (result) {
        setzeButtonsFuerValidierung(true)
        verarbeiteRueckmeldung(result, data)
      }
    })
  }

  const stoppeValidierung = () => {
    if (!isValidierungBeendet && id) {
      deleteRequest<ValidierungsAnfrageResponse>(`v1/${id}`, {
        handleResponseError: response => {
          if (response.status === 404) {
            updateProgressStatus("ERROR_VALIDATION_ID_NOT_FOUND")
          } else {
            updateProgressStatus("ERROR_INTERNAL_BACKEND")
          }
        },
        handleCaughtError: () => updateProgressStatus("ERROR_NO_BACKEND_RESPONSE")
      })
        .then(result => {
          if (result) {
            updateProgressStatus("CANCELED")
          }
        })
        .finally(() => {
          endPolling()
          setzeButtonsFuerValidierung(false)
        })
    }
  }

  // Rest Aufrufe bei Start der Application. Ohne diese Infos funktioniert sie nicht.
  useEffect(() => {
    ladeRedaktionssystem(new URLSearchParams(window.location.search).get("rs") ?? "BUND")
  }, [ladeRedaktionssystem])

  useEffect(() => {
    const validierungsId = new URLSearchParams(window.location.search).get("id")
    if (validierungsId) {
      fortschrittsAnfrage(validierungsId)
    }
  }, [fortschrittsAnfrage])

  if (isLoadingRedsyskonfig) {
    return <>Lade Konfiguration ...</>
  }
  if (!redsyskonfig) {
    return <>Backend nicht erreichbar. Wenden sie sich an den Administrator.</>
  }
  return (
    <>
      <main>
        <div className="rectangle-gesamt">
          {/*Komponente fuer Pfadangabe und Angabe von erlaubten Praefixen*/}
          <Eingabe
            isValidateDisabled={isValidateDisabled}
            setIsValidateDisabled={setIsValidateDisabled}
            isCancelDisabled={isCancelDisabled}
            status={progress.status}
            ladeRedaktionssystem={ladeRedaktionssystem}
            redsyskonfig={redsyskonfig}
            setIsCancelDisabled={setIsCancelDisabled}
            stoppeValidierung={stoppeValidierung}
            validierungsAnfrage={validierungsAnfrage}
          />
        </div>
        <div className="rectangle-ausgabe">
          {/*Komponente fuer Abfrage und Anzeige des aktuellen Validierungsfortschritts */}
          <div>
            {!reportWirdGeneriert && report && (
              <ReportButtons report={report} reportId={id} updateProgressStatus={updateProgressStatus} />
            )}
            {progress && (
              <FortschrittWrapper status={progress?.status}>
                <Fortschritt data={progress} />
              </FortschrittWrapper>
            )}
          </div>
          {isValidierungBeendet && reportWirdGeneriert && (
            <p className="wrapper-message">Report wird generiert... Bitte warten</p>
          )}
          {!reportWirdGeneriert && report && <Report report={report} />}
        </div>
      </main>
    </>
  )
}
