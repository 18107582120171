import * as React from "react"

import {ConfigInfoResponse, defaultConfigInfoResponse} from "../../models/InfoResponse"
import {WeitereValidatorInformationen} from "./WeitereValidatorInformationen"
import {WeitereXZufiVersionenInformationen} from "./WeitereXZufiVersionInformationen"
import {getRequest} from "../../services/HttpService"

export const WeitereInformationen: React.FC = () => {
  const [configInfo, setConfigInfo] = React.useState<ConfigInfoResponse>()

  React.useEffect(() => {
    getRequest<ConfigInfoResponse>("v1/validatorconfig").then(configInfoResponse => {
      setConfigInfo({
        ...defaultConfigInfoResponse,
        ...configInfoResponse
      })
    })
  }, [])

  return (
    <>
      <main>
        <div className="rectangle-gesamt">
          <div>
            <div className="header-info">Version:</div>
            <ul>
              <div className="entry-wrapper">
                <span className="entry">Backend: {configInfo?.backendVersion}</span>
                <div className="clear" />
              </div>
              <div className="entry-wrapper">
                <span className="entry">Fachvalidator: {configInfo?.fachvalidatorVersion}</span>
                <div className="clear" />
              </div>
            </ul>
            <div className="header-info-2">Folgende XZuFi-Versionen werden unterstützt:</div>
            {configInfo?.xzufiVersionen && (
              <WeitereXZufiVersionenInformationen xZufiVersionen={configInfo.xzufiVersionen} />
            )}
            <div className="header-info-2">
              Neben Schema-Konformität werden die Dokumente auf folgende Regeln überprüft:
            </div>
            {configInfo?.validatoren && <WeitereValidatorInformationen validatoren={configInfo.validatoren} />}
          </div>
        </div>
      </main>
    </>
  )
}
