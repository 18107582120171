import React from "react"
import {createRoot} from "react-dom/client"
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import {Validierung} from "./pages/Validierung"
import "./index.css"
import {Delay} from "./components/ausgabe/Delay"
import {Header} from "./components/ausgabe/Header"
import {WeitereInformationen} from "./components/ausgabe/WeitereInformationen"
import {Statistiken} from "./components/ausgabe/Statistiken"

declare global {
  interface Window {
    env: {
      BACKEND_URL: string
      HTTP_PROTOCOL_ENABLED: string
      KEYCLOAK_SERVER_URL: string
      KEYCLOAK_REALM: string
      KEYCLOAK_CLIENT_ID: string
    }
  }
}

const container = document.getElementById("root") as Element

if (container) {
  createRoot(container).render(
    <React.StrictMode>
      <Delay>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Validierung />} />
            <Route path="valido" element={<Validierung />} />
            <Route path="/information" element={<WeitereInformationen />} />
            <Route path="/statistiken" element={<Statistiken />} />
          </Routes>
        </Router>
      </Delay>
    </React.StrictMode>
  )
}
